require('./vendor/es5-shim/es5-shim');
require('./vendor/es5-shim/es5-sham');
require('./vendor/eventShim');
require('es6-shim');

window.$ = window.jQuery = require('./vendor/jquery/jquery-3.7.1');
require('./vendor/respond');
require('./vendor/angular/angular');
require('./vendor/ocLazyLoad');
require('./vendor/Modernizr');
var b64 = require('./vendor/base64');
window.btoa = window.btoa || b64.btoa;
window.atob = window.atob || b64.atob;
require('./vendor/jquery.hammer-full.min');
require('./vendor/angular-ui-router/release/angular-ui-router');
require('./vendor/angular/angular-animate.min');
require('./vendor/angular/angular-cookies.min');
require('./vendor/angular/angular-messages.min');
require('./vendor/angular/angular-aria.min');
require('./vendor/angular-sanitize/angular-sanitize.min');
require('./vendor/angular-ui-mask/dist/mask.min');

// ngAria causing keypress bindings to fire twice.  needs fixed before it can be enabled
bootstrap = angular.module("bootstrap.main", [
    "ui.router", "ngAnimate", "ngSanitize", /*"ngAria",*/ "ngCookies", "ngMessages", "oc.lazyLoad", "ui.mask",
    "bootstrap", "bootstrap.ui", "bootstrap.restservices", "bootstrap.ebusiness", "bootstrap.employer", "bootstrap.sales", "bootstrap.unified", "bootstrap.member"
]);
require('./services/aemUtils.js')(bootstrap);

var hostMatch = window.location.host.match(/(.*?)(d|t|p)env([0-9a-zA-Z]?)(.*?)\.(.*?)\./);
if (!hostMatch) {
    if (window.location.host === "html5dev.highmark.com") {
        hostMatch = ['','','t','2',''];
    }
    if (window.location.host.indexOf("localhost") === 0) {
        hostMatch = ['','','d','2',''];
    }
}
bootstrap.version = {
    major: '4',
    minor: '3',
    bugfix: '0',
    path: 'v4'
};
if (hostMatch) {
    bootstrap.local = hostMatch[2] === 'd' || hostMatch[2] === 'p';
    bootstrap.environment = hostMatch[3];
    bootstrap.domain = "static" + hostMatch[2] + "env" + bootstrap.environment + ".hmhs.com";
    bootstrap.URI_PREFIX = "https://" + bootstrap.domain;
}
else {
    bootstrap.local = false;
    bootstrap.environment = "";
    bootstrap.domain = "static.hmhs.com";
    bootstrap.URI_PREFIX = "https://" + bootstrap.domain;
}
bootstrap.buildURI = function(input) {
    input = input.replace('/v4/', '/{version}/');
    input = input.replace('/{version}/', '/'+bootstrap.version.path+'/');
    return bootstrap.URI_PREFIX + input + '/';
};

var scriptCount = 0;
var loadedCount = 0;
var bootstrapped = false;
var loadScript = function(URI, skipCount) {
    if (!skipCount) {
        scriptCount++;
    }
    var s = document.createElement('script');
    s.src = URI;
    s.onload = (function(skipCount) { return function() {
        if (!skipCount) {
            loadedCount++;
        }
        if (scriptCount == loadedCount && scriptCount > 0) {
            if (!bootstrapped) {
                bootstrapped = true;
                $(document).ready(function() {
                    angular.init(document, angular.bootstrap);
                });
            }
        }
    }})(skipCount);
    document.head.appendChild(s);
}

bootstrap.templates = {};
bootstrap.templates['page-templates/legal-policies.html'] = require('page-templates/legal-policies.html');
bootstrap.templates['page-templates/404.html'] = require('page-templates/404.html');
bootstrap.templates['page-templates/maintenance.html'] = require('page-templates/maintenance.html');
bootstrap.templates['page-templates/detail.html'] = require('page-templates/detail.html');
bootstrap.templates['page-templates/detailCustom.html'] = require('page-templates/detailCustom.html');
bootstrap.templates['page-templates/categoryDetail.html'] = require('page-templates/categoryDetail.html');
bootstrap.templates['page-templates/category.html'] = require('page-templates/category.html');
bootstrap.templates['page-templates/categoryCustom.html'] = require('page-templates/categoryCustom.html');
bootstrap.templates['page-templates/landing.html'] = require('page-templates/landing.html');
bootstrap.templates['page-templates/landing4.html'] = require('page-templates/landing4.html');
bootstrap.templates['page-templates/chevronDetail.html'] = require('page-templates/chevronDetail.html');

bootstrap.templates['page-templates/internal/landing.html'] = require('page-templates/internal/landing.html');

bootstrap.templates['page-templates/categoryCustomFullWidth.html'] = require('page-templates/categoryCustomFullWidth.html');
bootstrap.templates['page-templates/contentCustom.html'] = require('page-templates/contentCustom.html');

bootstrap.templates['page-templates/hmhs/admin-landing-page.html'] = require('page-templates/hmhs/admin-landing-page.html');
bootstrap.templates['page-templates/hmhs/content-page-side-bar-scrolling.html'] = require('page-templates/hmhs/content-page-side-bar-scrolling.html');
bootstrap.templates['page-templates/hmhs/content-page-side-bar.html'] = require('page-templates/hmhs/content-page-side-bar.html');
bootstrap.templates['page-templates/hmhs/multi-form-side-bar.html'] = require('page-templates/hmhs/multi-form-side-bar.html');
bootstrap.templates['page-templates/hmhs/content-page.html'] = require('page-templates/hmhs/content-page.html');
bootstrap.templates['page-templates/hmhs/admin-page.html'] = require('page-templates/hmhs/admin-page.html');
bootstrap.templates['page-templates/hmhs/landing-page.html'] = require('page-templates/hmhs/landing-page.html');
bootstrap.templates['page-templates/ebusiness/dashboard-page.html'] = require('page-templates/ebusiness/dashboard-page.html');

// loadScript('https://libs.coremetrics.com/eluminate.js', true); // not working as expected
var s = document.createElement('script');
//eluminate.js removed and cmNull file added 
s.src = bootstrap.URI_PREFIX+'/bootstrap/v4/cmNull.js';
s.type='text/javascript';
document.head.appendChild(s);

if (!window.skipAutoLoad) {
    loadScript(bootstrap.URI_PREFIX + '/bootstrap/v4/ebusiness/ebusiness.min.js');
    loadScript(bootstrap.URI_PREFIX + '/bootstrap/v4/sales/sales.min.js');
    loadScript(bootstrap.URI_PREFIX + '/bootstrap/v4/employer/employer.min.js');
    loadScript(bootstrap.URI_PREFIX + '/bootstrap/v4/member/member.min.js');
    loadScript(bootstrap.URI_PREFIX + '/bootstrap/v4/unified/unified.min.js');

    loadScript(bootstrap.URI_PREFIX + '/bootstrap/v4/restservices.min.js');
    loadScript(bootstrap.URI_PREFIX + '/bootstrap/v4/ui.min.js');
    loadScript(bootstrap.URI_PREFIX + '/bootstrap/v4/bootstrap.min.js');
}
