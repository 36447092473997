module.exports = function(bootstrap) {
	window.templateWithContent = function (templatePath, state) {
		return (function(state) {
			return ['$location', '$state', '$browser', '$templateFactory', '$stateParams', function($location, $state, $browser, $templateFactory, $stateParams) {
				var url = $state.href(state || $state.next.name).replace($browser.baseHref(),'').replace(/\.html/g,'') + '.employerTemplate.html/' + templatePath;
				return $templateFactory.fromUrl(url, $stateParams);
				// Below is incorrect, it doesn't factor in url params.  Retained here for code clarity.
				// return $templateFactory.fromUrl(url, $state.next.params);
			}]
		})(state);
	};
	bootstrap.service("aemUtils", [function() {
	}]);
}