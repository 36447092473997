/*! Respond.js v1.4.2: min/max-width media query polyfill
 * Copyright 2014 Scott Jehl
 * Licensed under MIT
 * http://j.mp/respondjs */

/*! matchMedia() polyfill - Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas. Dual MIT/BSD license */
/*! NOTE: If you're already including a window.matchMedia polyfill via Modernizr or otherwise, you don't need this part */
(function() {
	(function(w) {
	  "use strict";
	  w.matchMedia = w.matchMedia || function(doc, undefined) {
		var bool, docElem = doc.documentElement, refNode = docElem.firstElementChild || docElem.firstChild, fakeBody = doc.createElement("body"), div = doc.createElement("div");
		div.id = "mq-test-1";
		div.style.cssText = "position:absolute;top:-100em";
		fakeBody.style.background = "none";
		fakeBody.appendChild(div);
		return function(q) {
		  div.innerHTML = '&shy;<style media="' + q + '"> #mq-test-1 { width: 42px; }</style>';
		  docElem.insertBefore(fakeBody, refNode);
		  bool = div.offsetWidth === 42;
		  docElem.removeChild(fakeBody);
		  return {
			matches: bool,
			media: q
		  };
		};
	  }(w.document);
	})(this);

	(function(w) {
	  "use strict";
	  var respond = {};
	  w.respond = respond;
	  respond.update = function() {};
	  var requestQueue = [], xmlHttp = function() {
		var xmlhttpmethod = false;
		try {
		  xmlhttpmethod = new w.XMLHttpRequest();
		} catch (e) {
		  xmlhttpmethod = new w.ActiveXObject("Microsoft.XMLHTTP");
		}
		return function() {
		  return xmlhttpmethod;
		};
	  }(), ajax = function(url, callback) {
		var req = xmlHttp();
		if (!req) {
		  return;
		}
		req.open("GET", url, true);
		req.onreadystatechange = function() {
		  if (req.readyState !== 4 || req.status !== 200 && req.status !== 304) {
			return;
		  }
		  callback(req.responseText);
		};
		if (req.readyState === 4) {
		  return;
		}
		req.send(null);
	  }, isUnsupportedMediaQuery = function(query) {
		return query.replace(respond.regex.minmaxwh, "").match(respond.regex.other);
	  };
	  respond.ajax = ajax;
	  respond.queue = requestQueue;
	  respond.unsupportedmq = isUnsupportedMediaQuery;
	  respond.regex = {
		media: /@media[^\{]+\{([^\{\}]*\{[^\}\{]*\})+/gi,
		keyframes: /@(?:\-(?:o|moz|webkit)\-)?keyframes[^\{]+\{(?:[^\{\}]*\{[^\}\{]*\})+[^\}]*\}/gi,
		comments: /\/\*[^*]*\*+([^/][^*]*\*+)*\//gi,
		urls: /(url\()['"]?([^\/\)'"][^:\)'"]+)['"]?(\))/g,
		findStyles: /@media *([^\{]+)\{([\S\s]+?)$/,
		only: /(only\s+)?([a-zA-Z]+)\s?/,
		minw: /\(\s*min\-width\s*:\s*(\s*[0-9\.]+)(px|em)\s*\)/,
		maxw: /\(\s*max\-width\s*:\s*(\s*[0-9\.]+)(px|em)\s*\)/,
		minmaxwh: /\(\s*m(in|ax)\-(height|width)\s*:\s*(\s*[0-9\.]+)(px|em)\s*\)/gi,
		other: /\([^\)]*\)/g
	  };
	  respond.mediaQueriesSupported = w.matchMedia && w.matchMedia("only all") !== null && w.matchMedia("only all").matches;
	  if (respond.mediaQueriesSupported) {
	    return;
	  }
	  var doc = w.document, docElem = doc.documentElement, mediastyles = [], rules = [], appendedEls = [], parsedSheets = {}, resizeThrottle = 30, head = doc.getElementsByTagName("head")[0] || docElem, base = doc.getElementsByTagName("base")[0], links = head.getElementsByTagName("link"), lastCall, resizeDefer, eminpx, getEmValue = function() {
		var ret, div = doc.createElement("div"), body = doc.body, originalHTMLFontSize = docElem.style.fontSize, originalBodyFontSize = body && body.style.fontSize, fakeUsed = false;
		div.style.cssText = "position:absolute;font-size:1em;width:1em";
		if (!body) {
		  body = fakeUsed = doc.createElement("body");
		  body.style.background = "none";
		}
		docElem.style.fontSize = "100%";
		body.style.fontSize = "100%";
		body.appendChild(div);
		if (fakeUsed) {
		  docElem.insertBefore(body, docElem.firstChild);
		}
		ret = div.offsetWidth;
		if (fakeUsed) {
		  docElem.removeChild(body);
		} else {
		  body.removeChild(div);
		}
		docElem.style.fontSize = originalHTMLFontSize;
		if (originalBodyFontSize) {
		  body.style.fontSize = originalBodyFontSize;
		}
		ret = eminpx = parseFloat(ret);
		return ret;
	  }, applyMedia = function(fromResize) {
		var name = "clientWidth", docElemProp = docElem[name], currWidth = doc.compatMode === "CSS1Compat" && docElemProp || doc.body[name] || docElemProp, styleBlocks = {}, lastLink = links[links.length - 1], now = new Date().getTime();
		if (fromResize && lastCall && now - lastCall < resizeThrottle) {
		  w.clearTimeout(resizeDefer);
		  resizeDefer = w.setTimeout(applyMedia, resizeThrottle);
		  return;
		} else {
		  lastCall = now;
		}
		for (var i in mediastyles) {
		  if (mediastyles.hasOwnProperty(i)) {
			var thisstyle = mediastyles[i], min = thisstyle.minw, max = thisstyle.maxw, minnull = min === null, maxnull = max === null, em = "em";
			if (!!min) {
			  min = parseFloat(min) * (min.indexOf(em) > -1 ? eminpx || getEmValue() : 1);
			}
			if (!!max) {
			  max = parseFloat(max) * (max.indexOf(em) > -1 ? eminpx || getEmValue() : 1);
			}
			if (!thisstyle.hasquery || (!minnull || !maxnull) && (minnull || currWidth >= min) && (maxnull || currWidth <= max)) {
			  if (!styleBlocks[thisstyle.media]) {
				styleBlocks[thisstyle.media] = [];
			  }
			  styleBlocks[thisstyle.media].push(rules[thisstyle.rules]);
			}
		  }
		}
		for (var j in appendedEls) {
		  if (appendedEls.hasOwnProperty(j)) {
			if (appendedEls[j] && appendedEls[j].parentNode === head) {
			  head.removeChild(appendedEls[j]);
			}
		  }
		}
		appendedEls.length = 0;
		for (var k in styleBlocks) {
		  if (styleBlocks.hasOwnProperty(k)) {
			var ss = doc.createElement("style"), css = styleBlocks[k].join("\n");
			ss.type = "text/css";
			ss.media = k;
			head.insertBefore(ss, lastLink.nextSibling);
			if (ss.styleSheet) {
			  ss.styleSheet.cssText = css;
			} else {
			  ss.appendChild(doc.createTextNode(css));
			}
			appendedEls.push(ss);
		  }
		}
	  }, translate = function(styles, href, media) {
		var qs = styles.replace(respond.regex.comments, "").replace(respond.regex.keyframes, "").match(respond.regex.media), ql = qs && qs.length || 0;
		href = href.substring(0, href.lastIndexOf("/"));
		var repUrls = function(css) {
		  return css.replace(respond.regex.urls, "$1" + href + "$2$3");
		}, useMedia = !ql && media;
		if (href.length) {
		  href += "/";
		}
		if (useMedia) {
		  ql = 1;
		}
		for (var i = 0; i < ql; i++) {
		  var fullq, thisq, eachq, eql;
		  if (useMedia) {
			fullq = media;
			rules.push(repUrls(styles));
		  } else {
			fullq = qs[i].match(respond.regex.findStyles) && RegExp.$1;
			rules.push(RegExp.$2 && repUrls(RegExp.$2));
		  }
		  eachq = fullq.split(",");
		  eql = eachq.length;
		  for (var j = 0; j < eql; j++) {
			thisq = eachq[j];
			if (isUnsupportedMediaQuery(thisq)) {
			  continue;
			}
			mediastyles.push({
			  media: thisq.split("(")[0].match(respond.regex.only) && RegExp.$2 || "all",
			  rules: rules.length - 1,
			  hasquery: thisq.indexOf("(") > -1,
			  minw: thisq.match(respond.regex.minw) && parseFloat(RegExp.$1) + (RegExp.$2 || ""),
			  maxw: thisq.match(respond.regex.maxw) && parseFloat(RegExp.$1) + (RegExp.$2 || "")
			});
		  }
		}
		applyMedia();
	  }, makeRequests = function() {
		if (requestQueue.length) {
		  var thisRequest = requestQueue.shift();
		  ajax(thisRequest.href, function(styles) {
			translate(styles, thisRequest.href, thisRequest.media);
			parsedSheets[thisRequest.href] = true;
			w.setTimeout(function() {
			  makeRequests();
			}, 0);
		  });
		}
	  }, ripCSS = function() {
		for (var i = 0; i < links.length; i++) {
		  var sheet = links[i], href = sheet.href, media = sheet.media, isCSS = sheet.rel && sheet.rel.toLowerCase() === "stylesheet";
		  if (!!href && isCSS && !parsedSheets[href]) {
			if (sheet.styleSheet && sheet.styleSheet.rawCssText) {
			  translate(sheet.styleSheet.rawCssText, href, media);
			  parsedSheets[href] = true;
			} else {
				requestQueue.push({
				  href: href,
				  media: media
				});
			}
		  }
		}
		makeRequests();
	  };
	  ripCSS();
	  respond.update = ripCSS;
	  respond.getEmValue = getEmValue;
	  function callMedia() {
		applyMedia(true);
	  }
	  if (w.addEventListener) {
		w.addEventListener("resize", callMedia, false);
	  } else if (w.attachEvent) {
		w.attachEvent("onresize", callMedia);
	  }
	})(this);
}).bind(window)();